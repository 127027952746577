<template lang="pug">
TheBook(
	:title="regions.title"
	:endpoint="regions.endpoint"
	:path="regions.path"
	:left="regions.left"
	:leftSub="regions.leftSub"
)
</template>

<script>
import TheBook from '@/components/TheBook'
import BOOKS from '@/modules/constants/books'

export default {
	name: 'Regions',
	components: { TheBook },
	setup () {
		const { regions } = BOOKS

		return {
			regions
		}
	}
}
</script>
